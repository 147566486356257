import { isAfter, parseISO } from "date-fns";
import { Fragment } from "react";

import { LayoutDashboard, TextLink } from "../../../components";
import type { CurriculumLesson, CurriculumLessonId } from "../../api";
import { CurriculumClass1 } from "../../classes/CurriculumClass1";
import { CurriculumClass2 } from "../../classes/CurriculumClass2";
import { CurriculumClass3 } from "../../classes/CurriculumClass3";
import { CurriculumClass4 } from "../../classes/CurriculumClass4";
import { CurriculumClass5 } from "../../classes/CurriculumClass5";
import { CurriculumClass6 } from "../../classes/CurriculumClass6";

export type CurriculumHomePageProps = {
  readonly lessons: readonly CurriculumLesson[];
  readonly onSignOutClick: () => void;
  readonly userFullName: string;
};

const lessonTitles: Record<CurriculumLessonId, string> = {
  [CurriculumClass1.classKey as CurriculumLessonId]: CurriculumClass1.title,
  [CurriculumClass2.classKey as CurriculumLessonId]: CurriculumClass2.title,
  [CurriculumClass3.classKey as CurriculumLessonId]: CurriculumClass3.title,
  [CurriculumClass4.classKey as CurriculumLessonId]: CurriculumClass4.title,
  [CurriculumClass5.classKey as CurriculumLessonId]: CurriculumClass5.title,
  [CurriculumClass6.classKey as CurriculumLessonId]: CurriculumClass6.title,
};

export function CurriculumHomePage({
  lessons,
  onSignOutClick,
  userFullName,
}: CurriculumHomePageProps) {
  return (
    <LayoutDashboard
      curriculumFeature
      onSignOutClick={onSignOutClick}
      page="curriculum"
      userFullName={userFullName}
    >
      <h1 className="mb-4 text-4xl">Classes</h1>

      <ul>
        {lessons.map((lesson) => {
          const title = lessonTitles[lesson.lessonId];
          if (!title) return <Fragment key={lesson.lessonId} />;

          const isComplete = lesson.dateComplete !== null;
          const isFuture = isAfter(parseISO(lesson.dateAvailable), new Date());

          return (
            <li className="mb-2" key={lesson.lessonId}>
              <TextLink
                disabled={isComplete || isFuture}
                to={`/curriculum/${lesson.classType}/${lesson.lessonId}`}
                type="linkInternal"
              >
                {title}
                {isComplete ? " (Complete)" : ""}
                {isFuture
                  ? ` (Opens ${parseISO(lesson.dateAvailable).toDateString()})`
                  : ""}
              </TextLink>
            </li>
          );
        })}
      </ul>
    </LayoutDashboard>
  );
}
