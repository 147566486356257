import type { RouteComponentProps } from "@reach/router";

import {
  CurriculumHomePageController,
  CurriculumSessionLoader,
} from "../../curriculum";
import { assertDefined } from "../../utils";

export type CurriculumHomePageProps = Readonly<RouteComponentProps>;

export default function CurriculumHomePage({
  location,
}: CurriculumHomePageProps) {
  assertDefined(location);

  return (
    <CurriculumSessionLoader location={location}>
      <CurriculumHomePageController locationPathname={location.pathname} />
    </CurriculumSessionLoader>
  );
}
