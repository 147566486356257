import { skipToken } from "@reduxjs/toolkit/query";
import { useCallback } from "react";

import { useUserGetQuery } from "../../../api";
import { LoadingScreen } from "../../../components";
import { sessionSignOut } from "../../../session";
import { useAppDispatch } from "../../../store";
import {
  type CurriculumSwapUserId,
  useCurriculumApiGetLessonsQuery,
} from "../../api";
import { CurriculumHomePage } from "../CurriculumHomePage";

export type CurriculumHomePageControllerProps = {
  readonly locationPathname: string;
};

export function CurriculumHomePageController({
  locationPathname,
}: CurriculumHomePageControllerProps) {
  const dispatch = useAppDispatch();
  const handleSignOutClick = useCallback(async () => {
    dispatch(sessionSignOut({ locationPathname }));
  }, [dispatch, locationPathname]);
  const userGetQuery = useUserGetQuery();

  const userFullName = userGetQuery.data
    ? `${userGetQuery.data.first_name} ${userGetQuery.data.last_name}`
    : "";

  const getLessonsQuery = useCurriculumApiGetLessonsQuery(
    userGetQuery.data?.user_id
      ? (userGetQuery.data?.user_id as CurriculumSwapUserId)
      : skipToken,
  );

  if (!getLessonsQuery.data) return <LoadingScreen />;

  return (
    <CurriculumHomePage
      lessons={getLessonsQuery.data}
      onSignOutClick={handleSignOutClick}
      userFullName={userFullName}
    />
  );
}
